import { Component, props } from "react"
import localStorage from "localStorage"
import responseType from "./responseType"
import { message } from "antd"
import httpUtils from "./httpUtils"
import config from "./config"

const AES = require("crypto-js")
const key = AES.enc.Utf8.parse("0123456789LONGBO") //十六位十六进制数作为密钥
const iv = AES.enc.Utf8.parse("LONGBO0123456789") //十六位十六进制数作为密钥偏移量

export default class api extends Component {
  static localSave(key, data) {
    localStorage.setItem(key, data)
  }
  static localGet(key) {
    return localStorage.getItem(key)
  }
  static localClear() {
    localStorage.clear()
  }

  static convertNull = (str) => {
    if (str == null || str == undefined) return ""
    return str
  }

  static qryhightlight = (_content,_qryText) =>{
  //  console.log('_content==',_content)
  //  console.log('_qryText==',_qryText)
    if (_qryText=='') return _content;
    else{
      var reg = new RegExp((`(${_qryText})`), "gm");
      var replace = '<span style="color:#fb9312;">'+_qryText+'</span>';
      return _content.replace(reg, replace);
    } 
   }

  static decode = (word) => {
    const encryptedHexStr = AES.enc.Hex.parse(word)
    const src = AES.enc.Base64.stringify(encryptedHexStr)
    const decrypt = AES.AES.decrypt(src, key, {
      iv,
      mode: AES.mode.CBC,
      padding: AES.pad.Pkcs7,
    })
    const decryptedStr = decrypt.toString(AES.enc.Utf8)
    let code = decryptedStr.toString()
    return code
  }
  static encode = (word) => {
    const src = AES.enc.Utf8.parse(word)
    const encrypted = AES.AES.encrypt(src, key, {
      iv,
      mode: AES.mode.CBC,
      padding: AES.pad.Pkcs7,
    })
    let code = encrypted.ciphertext.toString().toUpperCase()
    return code
  }

  static checkLogin() {
    return this.convertNull(this.localGet(config.user_key)) != ""
  }
  static trim(value) {
    return value.replace(/(^\s*)|(\s*$)/g, "")
  }
  static splitContent(strContent) {
    //去掉内容分段前的空格
    try {
      console.log("strContent=-=", strContent)
      var arys = strContent.split("<br>")
      if (arys) {
        var result = ""
        arys.forEach((item) => {
          var str = this.trim(item)
          var iempty = str.indexOf("&nbsp;")
          //console.log('str==',str)
          while (iempty > -1) {
            //仅替换开头的空格
            console.log("iempty==", iempty)
            //str = util.trim(str.substring(6,str.length-6));
            str = str.replace("&nbsp;", "") //replace(/&nbsp;/g, '');
            console.log("str trim==", str)
            iempty = str.indexOf("&nbsp;")
          }
          console.log("str==", str)
          if (str) {
            result += str + "<br><br>"
          }
        })
        return result
      }
    } catch (e) {}
    return strContent
  }

  static convetHtml = (contents) => {
    contents = this.convertNull(contents)
    if (contents != "") {
      contents = contents.replace(/\r\n/g, "<br>")
      contents = contents.replace(/\n/g, "<br>")
      contents = contents.replace(/\r/g, "<br>")
      contents = contents.replace(/  /g, "&nbsp;")

      contents = contents.replace(/&lt;/g, "<")
      contents = contents.replace(/&amp;/g, "&;")
      contents = contents.replace(/&quot;/g, '"')

      contents = contents.replace(/&gt;/g, ">")
      contents = contents.replace(/&#39;/g, "'")
    }
    return contents
  }

  static checkInfoLogin = (resdata) => {
    if (resdata && resdata.Table && resdata.Table.length > 0) {
      if (resdata.Table[0].ERRORINFO) {
        return false
      }
    }
    return true
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static login = (username, pwd) => {
    let url =
      config.server_url +
      "login&d=" +
      new Date().getTime() +
      "&txtLoginUser=" +
      username +
      "&txtLoginPwd=" +
      pwd
    console.log("login url=", url)
    return httpUtils
      .getRequest(url, {})
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getReportMenu = () => {
    let url = config.server_url_app + "getMenuByType&fType=2"
    console.log("menu url=", url)
    return httpUtils
      .getRequest(url, {})
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  static getIndustry() {
    let url = config.server_url + "getWebInfoReportCode"
    console.log("indusy url=", url)
    return httpUtils
      .getRequest(url, {})
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  static queryReport(opType, param) {
    let url = config.server_url + opType
    console.log("report.opType==", opType)
    console.log("report.param==", param)
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }
  static getReportByMenuId(menuId, pageIndex) {
    let url = config.server_url + "getWebInfoByMenu"
    let param = {
      d: new Date().getTime(),
      menuId: menuId,
      pageIndex: pageIndex,
      fType: 2,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  static getMoreReport(qryText, qryType) {
    let url = config.server_url_app + "getKlcjReport"
    let param = {
      menuId: "",
      pageIndex: 1,
      pageSize: 10,
      qryText: qryText,
      qryType: qryType,
      qryType: "",
      userid: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  static getReportDetail(guid) {
    let url = config.server_url_app + "getInfoDetail_app"
    let param = {
      guid: guid,
      type: 2,
      userid: this.localGet(config.user_key)//"cd7e5473-01cb-11e5-8702-984be171589b",
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getMeetingMenu = () => {
    return this.getCode("meetingInvestType", 1, 100)
  }
  
  static getCode = (type, pageIndex, pageSize) => {
    let url =
      config.server_url +
      "getCodeByType&typeCode=" +
      type +
      "&pageIndex=" +
      pageIndex +
      "&pageSize=" +
      pageSize
    console.log("getMeetingMenu url=", url)
    return httpUtils
      .getRequest(url, {})
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getMeeting = (fType, pageIndex, qryText, qryDateS, qryDateE) => {
    let url = config.server_url + "getWebMeetingInvest"
    console.log("getWebMeetingInvest url=", url)
    let param = {
      d: new Date().getTime(),
      qryText: qryText,
      pageIndex: pageIndex,
      fType: fType,
      qryDateS: qryDateS,
      qryDateE: qryDateE,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getMeetingDetail = (guid) => {
    let url = config.server_url + "getWebMeetingInvestDetail"
    console.log("getMeetingDetail url=", url)
    let param = {
      d: new Date().getTime(),
      guid: guid,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }
  static getTpsNewsMenu = () => {
    return this.getCode("tps_news_NewsType", 1, 100)
  }
  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getTpsNews = (fType, pageIndex, qryText, qryDateS, qryDateE,qryStock,qryTitleItem) => {
    let url = config.server_url + "getWebTpsNews"
    let param = {
      d: new Date().getTime(),
      qryText: qryText,
      pageIndex: pageIndex,
      fType: fType,
      qryDateS: qryDateS,
      qryDateE: qryDateE,
      staffId: this.localGet(config.user_key),
      qryStock:qryStock,
      qryTitleItem:qryTitleItem
    }
    console.log('getTpsNews param=',param)
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getTpsNewsDetail = (guid) => {
    let url = config.server_url + "getWebTpsNewsDetail"
    let param = {
      d: new Date().getTime(),
      guid: guid,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }
  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
   static getCorpDataMenu =()=> {
    let url = config.server_url + "getWebCorpDataMenu"
    let param = {
      d: new Date().getTime(),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }
  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
   static getCorpDataContent = (menuId) => {
    let url = config.server_url + "getWebCorpDataContent"
    let param = {
      d: new Date().getTime(),
      menuId: menuId
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * 金股次数
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getGoldStockNumber = (month) => {
    let url = config.server_url + "getWebGoldStockNumber"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  /**
   * 金股汇总
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getGoldStockTotal = (month) => {
    let url = config.server_url + "getWebGoldStockTotal"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  /**
   * 行业热度轮动
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getWebGoldStockIndustryHot = (month) => {
    let url = config.server_url + "getWebGoldStockIndustryHot"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  /**
   * 首次 月均受益
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getWebGoldStockHotSecu = (month) => {
    let url = config.server_url + "getWebGoldStockHotSecu"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  /**
   * 行业月均受益
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getWebGoldStockIndustryDistribute = (month) => {
    let url = config.server_url + "getWebGoldStockIndustryDistribute"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  /**
   * 金股组合
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getGoldStockCompose = (month) => {
    let url = config.server_url + "getWebGoldStockCompose"
    let param = {
      d: new Date().getTime(),
      month: month,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   * 金股明细
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getGoldStockDetail = (composeId) => {
    let url = config.server_url + "getWebGoldStockDetail"
    let param = {
      d: new Date().getTime(),
      composeId: composeId,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  /**
   * 事件
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getEventList = (qryText, pageIndex, pageSize, qryDateS, qryDateE,bankuai) => {
    let url = config.server_url + "getWebCorpEventList"
    let param = {
      d: new Date().getTime(),
      qryText: qryText,
      qryDateS: qryDateS,
      qryDateE: qryDateE,
      pageIndex: pageIndex,
      pageSize: pageSize,
      staffId: this.localGet(config.user_key),
      bankuai:bankuai
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   * 观点
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpViewList = (
    qryType,
    qryText,
    pageIndex,
    pageSize,
    qryDateS,
    qryDateE,
    qrySource,
    qryAuthor,
    qryReportType
  ) => {
    let url = config.server_url + "getWebCorpViewList"
    let param = {
      d: new Date().getTime(),
      qryType: this.convertNull(qryType),
      qryText: this.convertNull(qryText),
      qrySource: this.convertNull(qrySource),
      qryAuthor: this.convertNull(qryAuthor),
      qryDateS: qryDateS,
      qryDateE: qryDateE,
      pageIndex: pageIndex,
      pageSize: pageSize,
      staffId: this.localGet(config.user_key),
      qryReportType:qryReportType
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  
  /**
   *
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpViewDetail = (id) => {
    let url = config.server_url + "getWebCorpViewDetail"
    let param = {
      id: id,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }

  /**
   * 社群
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpGroupList = (
    qryType,
    qryText,
    pageIndex,
    pageSize,
    qryCity
  ) => {
    let url = config.server_url + "getWebCorpGroupList"
    let param = {
      d: new Date().getTime(),
      qryType: this.convertNull(qryType),
      qryText: this.convertNull(qryText),
      pageIndex: pageIndex,
      pageSize: pageSize,
      staffId: this.localGet(config.user_key),
      qryCity: this.convertNull(qryCity),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   *
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpGroupDetail = (id) => {
    let url = config.server_url + "getWebCorpGroupDetail"
    let param = {
      id: id,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   * 奖项
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpAwardList = (
    qryType,
    qryText,
    pageIndex,
    pageSize,
    qryDateS,
    qryDateE,
    qrySource
  ) => {
    let url = config.server_url + "getWebCorpAwardList"
    let param = {
      d: new Date().getTime(),
      qryType: this.convertNull(qryType),
      qryText: this.convertNull(qryText),
      qrySource: this.convertNull(qrySource),
      qryDateS: qryDateS,
      qryDateE: qryDateE,
      pageIndex: pageIndex,
      pageSize: pageSize,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   * 奖项
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getCorpAwardDetail = (
    qryType,
    qryFullCode,
    pageIndex,
    pageSize,
    qryComCode
  ) => {
    let url = config.server_url + "getWebCorpAwardDetail"
    let param = {
      d: new Date().getTime(),
      qryType: this.convertNull(qryType),
      qryFullCode: this.convertNull(qryFullCode),
      qryComCode: this.convertNull(qryComCode),
      pageIndex: pageIndex,
      pageSize: pageSize,
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }
  /**
   *
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static saveCorpGroupCard = (companyCode, companyName, content) => {
    let url = config.server_url + "saveWebCorpCard"
    let param = {
      companyCode: companyCode,
      companyName: companyName,
      content: encodeURIComponent(content),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error("获取数据失败")
      })
  }

  /**
   * 注册
   * @param params 请求参数
   * @returns {Promise}
   */
  static regist = (param) => {
    let url = config.server_url + "reg"
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  static getMyInfo = () => {
    let url = config.server_url + "getMyInfo"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static getParters = () => {
    let url = config.server_url + "getParters"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static getContact = () => {
    let url = config.server_url + "getContact"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static getBuy = () => {
    let url = config.server_url + "getVipPrice"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  static saveDown = (report) => {
    let url = config.server_url + "saveDown"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
      reportId: report.ID,
      title: report.FTITLE,
      time: report.CREATEDATE1,
      size: report.PAGESIZE,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  static getDown = () => {
    let url = config.server_url + "getDown"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  static saveFeedBack = (content) => {
    let url = config.server_url + "saveFeedBack"
    let param = {
      d: new Date().getTime(),
      userName: this.localGet(config.user_key),
      content: content,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }

  static chgPwd = (password) => {
    let url = config.server_url + "chgPwd"
    let param = {
      d: new Date().getTime(),
      s: this.localGet(config.user_key),
      txtPassword: password,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static forgot = (email) => {
    let url = config.server_url + "forgot_hxnn"
    let param = {
      d: new Date().getTime(),
      txtEmail: email,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static resetPwd = (id, password) => {
    let url = config.server_url + "chgPwd"
    let param = {
      d: new Date().getTime(),
      s: id,
      txtPassword: password,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
  static goLink = (link) => {
    if (this.convertNull(link) != "") {
      window.open(link, "_blank")
    }
  }
  static goWebSite = (link) => {
    if (this.convertNull(link) != "") {
      link = link.toUpperCase().indexOf("HTTP") == -1 ? "http://" + link : link
      window.open(link, "_blank")
    }
  }
  static applyToken = (corp,position,mobile,email) => {
    let url = config.server_url + "applyToken"
    let param = {
      d: new Date().getTime(),
      staffId: this.localGet(config.user_key),
      corp: corp,
      position: position,
      mobile: mobile,
      email: email,
    }
    return httpUtils
      .getRequest(url, param)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log("errror==", error)
        message.error(error)
      })
  }
}
