import React from "react"
import moment from "moment"
import styled from "styled-components"
import bg from "../../assets/imgs/index/bg.jpg"
import { Link } from "react-router-dom"
import qr from "../../assets/imgs/index/qrcode.png"
import zsxq from "../../assets/imgs/index/zsxq.png"
import ztzq from "../../assets/imgs/index/ztzq.png"
import { navList } from "../../layout/main"
import { Checkbox, message, Spin, Row, Col } from "antd"
import CaptchaMini from "captcha-mini"
import img1 from "../../assets/imgs/index/module1.png"
import img2 from "../../assets/imgs/index/module2.png"
import img3 from "../../assets/imgs/index/module3.png"
import img4 from "../../assets/imgs/index/module4.png"
import config from "../../util/config"
import api from "../../util/api"

const IndexCss = styled.div`
  background: #f4f5f7;
  padding-bottom: 110px;
  .login {
    height: 730px;
    background: url(${bg}) no-repeat;
    background-size: cover;
    .login_contrain {
      .login_content {
        padding-top: 100px;
        display: flex;
        justify-content: space-between;
        .left {
          color: #fff;
          width: 550px;
          font-weight: 300;
          font-size: 35px;
          p {
            &:first-child {
              font-weight: 600;
              font-size: 40px;
              margin-bottom: 20px;
            }
          }
          .download {
            width: 630px;
            margin-top: 100px;
            margin-left:-30px;
            text-align: center;
            font-size: 20px;
            // img {
            //   display: block;
            //   width: 100%;
            //   height: 220px;
            //   margin-bottom: 20px;
            // }
          }
          .ztimg{
            margin-top: 180px;
            width:194px;
            height:51px;
          }
        }
        
        .right {
          width: 568px;
          height: 500px;
          background: rgba(35, 72, 112, 0);
          border-radius: 30px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;
          transform-origin: center center;
          &:hover {
            width: 541px;
            background: rgba(35, 72, 112, 0.3);
          }
          input {
            color: #fff;
            border: 0;
            width: 100%;
            height: 100%;
            font-size: 21px;
            outline: none;
            background: transparent;
            &::placeholder {
              color: #fff;
            }
          }
          .ipt {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.3);
            border: 1px solid #dddddd;
            border-radius: 25px;
            overflow: hidden;
            margin-bottom: 30px;
            input {
              padding: 0 25px;
              transition: all 0.3s;
              width: 381px;
              height: 49px;
              &:focus {
                width: 452px;
                background: rgba(35, 72, 112, 0.6);
              }
            }
          }
          .code {
            display: flex;
            align-items: center;
            width: 381px;
            justify-content: space-between;
            p {
              flex: 1;
              height: 49px;
              background: rgba(255, 255, 255, 0.3);
              overflow: hidden;
              border: 2px solid transparent;
              border-radius: 25px;
              &:first-child {
                border: 2px solid #dddddd;
                margin-right: 30px;
                input {
                  padding: 0 25px;
                }
              }
              img {
                display: block;
                width: 100%;
                height: 100%;
              }
            }
          }
          .login_btn {
            width: 381px;
            line-height: 49px;
            background: linear-gradient(-90deg, #1e86d3, #199cd1);
            border-radius: 25px;
            text-align: center;
            font-weight: 600;
            cursor: pointer;
            font-size: 21px;
            margin-top: 37px;
          }
          .active_area {
            width: 381px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 32px;
            font-size: 18px;
            .ant-checkbox-wrapper {
              color: #fff;
              font-size: 18px;
            }
            span {
              cursor: pointer;
            }
            .link {
              cursor: pointer;
              height: 68px;
              display: block;
              font-weight:bold;
              color: #fff;
              &:hover {
                background: #49a3e3;
              }
            }
            .a {
              background: #3c81ce;
            }
          }
        }
      }
    }
  }
  .margin {
    margin: 82px 0 59px 0;
  }
  .modules {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    font-size: 15px;
    gap: 10px;
    grid-gap: 10px;
    li {
      flex: 1;
      cursor: pointer;
      width: 380px;
      padding: 28px 24px;
      height: 270px;
      background: #ffffff;
      color: #000;
      p {
        &:first-child {
          font-size: 20px;
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          img {
            margin-right: 16px;
          }
        }
      }
    }
  }
  .divCoopFoot{
     display:flex;
     justify-content: center;
     align-items:center;
     font-weight: bold;
     margin-top: 5px;
  }
  
  .coopImgIon{
    height: 19px;
  }
  .coopImg{
    display: inline-block;
    vertical-align: middle;
  }
`

const TitleCss = styled.div`
  padding-left: 10px;
  position: relative;
  color: #000000;
  p {
    font-size: 25px;
    font-weight: 600;
    &:first-child {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  &:before {
    content: "";
    width: 3px;
    height: 90%;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const Title = ({ title, className, subTitle }) => (
  <TitleCss className={className}>
    <p>{title}</p>
    <p>{subTitle}</p>
  </TitleCss>
)

class Index extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: false,
      loading: false,
      captchaCode: "",
      parterList:[]
    }
  }
  componentDidMount() {
    console.log("index........")
    let saveTime = api.localGet(config.save_time_key)
    if (saveTime != moment().format("YYYY-MM-DD")) {
      api.localClear()
      let captcha1 = new CaptchaMini()
      captcha1.draw(document.querySelector("#captcha1"), (r) => {
        console.log(r, "验证码1")
        this.setState({
          captchaCode: r.toUpperCase(),
        })
      })
    } else {
      let staffId = api.localGet(config.user_key)
      if (staffId && staffId.length > 0) {
        this.setState({
          isLogin: true,
        })
      }
    }
    this.getParters()
  }
  getParters(){
    api.getParters().then((res) => {
      console.log("getParters=", res)
      if (res && res.Table) {
         this.setState({
           parterList:res.Table
         })
      }
    })
  }
  login() {
    let username = this.refs.username.value
    let pwd = this.refs.pwd.value
    if (username.length == 0 || pwd.length == 0) {
      message.error("请填写用户名、密码")
      return
    }
    if (this.refs.rescode.value.toUpperCase() != this.state.captchaCode) {
      message.error("请填写正确的验证码")
      return
    }
    this.setState({
      loading: true,
    })
    api.login(username, pwd).then((res) => {
      console.log("res=", res)
      this.setState({
        loading: false,
      })
      if (res && res.valid) {
        api.localSave(config.user_key, res.staffId)
        api.localSave(config.save_time_key, moment().format(config.dateFormat))
        this.setState({
          isLogin: true,
        })
        message.success("登录成功")
        //this.props.history.push("/report")
      } else {
        message.error("对不起，登录失败")
      }
    })
  }
  onChange = (e) => {
    console.log(e.target.checked)
  }
  onKeyupUser(e) {
    if (e.keyCode === 13) {
      this.refs.pwd.focus()
    }
  }
  onKeyupPwd(e) {
    if (e.keyCode === 13) {
      this.refs.rescode.focus()
    }
  }
  onKeyupRes(e) {
    if (e.keyCode === 13) {
      this.login()
    }
  }
  render() {
    const img = { img1, img2, img3, img4 }
    const { isLogin, loading ,parterList} = this.state
    return (
      <IndexCss>
        <div className="login">
          <div className="login_contrain">
            <div className="maxContrain login_content">
              <div className="left">
                <p>
                  没有信息差&nbsp;&nbsp;&nbsp;&nbsp;机构零距离
                  <br />
                  机构投资大数据
                </p>
                <p>
                  机构研报，投资笔记，机构会议，机构金股，机构主题，一屏掌握！
                </p>
                <div className="download">
                  <Row gutter={3}>
                    <Col span={12}>
                      <img src={zsxq} alt="" />
                      <p style={{ "marginTop": "10px" }}>加入知识星球会员</p>
                    </Col>

                  </Row>
                </div>

              </div>
              <div>


              </div>
              {!isLogin ? (
                <Spin spinning={loading}>
                  <div className="right">
                    <p className="ipt">
                      <input
                        autoFocus
                        type="text"
                        placeholder="账号"
                        ref="username"
                        onKeyUp={this.onKeyupUser.bind(this)}
                      />
                    </p>
                    <p className="ipt">
                      <input type="password" placeholder="密码" ref="pwd" onKeyUp={this.onKeyupPwd.bind(this)} />
                    </p>
                    <div className="code">
                      <p>
                        <input type="text" placeholder="验证码" ref="rescode" onKeyUp={this.onKeyupRes.bind(this)} />
                      </p>
                      <p>
                        {/* <img src="" alt="" /> */}
                        <canvas width="170" height="45" id="captcha1"></canvas>
                      </p>
                    </div>
                    <p className="login_btn" onClick={this.login.bind(this)}>
                      登录
                    </p>
                    <p className="active_area">
                      <Checkbox onChange={this.onChange}>自动登录</Checkbox>
                      {/* <span onClick={this.props.histroy.push("/forgot")}>
                        忘记密码？
                      </span> */}
                    </p>
                    <p className="active_area">
                      <Link className="link" to="/forgot">
                        <span>忘记密码? </span>
                      </Link>
                      <Link className="link" to="/regist">
                        <span>注册 {">>"} </span>
                      </Link>
                    </p>
                  </div>
                </Spin>
              ) : (
                  <span></span>
                )}
            </div>
          </div>
        </div>
        <div className="maxContrain">
          <Title title="核心模块" className="margin" subTitle="Core Module" />
          <ul className="modules">
            {navList.map((t, i) => {
              return i > 0 && t.name != "我的信息" ? (
                <Link to={t.path} key={i}>
                  <li key={i}>
                    <p>
                      <img src={img[`img${i}`]} alt="" />
                      {t.name}
                    </p>
                    <p><div dangerouslySetInnerHTML={{ __html: t.desc }}></div>
                    </p>
                  </li>
                </Link>
              ) : null
            })}
          </ul>
        </div>
        <div className="maxContrain">
          <Title title="合作机构" className="margin" subTitle="Partners" />
          <ul className="modules">
            {parterList.map((t, i) => {
              return (
                // <Link to={t.path} key={i}>
                <a href={t.path} target="_blank">
                  <li key={t.id}>
                    <p>
                      <img src={t.logo} alt="" className="coopImgIon"/>
                      {t.name}
                    </p>
                    <p>
                      <div style={{textAlign: "center"}}>
                      <img src={t.src} alt="" className="coopImg"/>
                      {/* <div dangerouslySetInnerHTML={{ __html: t.desc }} className="divCoopFoot"></div> */}
                      </div>
                    </p>
                  </li>
                  </a>
                // </Link>
              )
            })}
          </ul>
        </div>
      </IndexCss>
    )
  }
}

export default Index
