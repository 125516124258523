import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import "./style/index.scss"
import ReportDetail from "./detail"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Table,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import config from "../../util/config"
import CheckableTag from "antd/lib/tag/CheckableTag"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const { RangePicker } = DatePicker

const TitleCss = styled.div`
  padding-left: 8px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  color: #000000;
  &:before {
    content: "";
    width: 2px;
    height: 19px;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`
const TitleQryBtnCss = styled.div`
  float: right;
  margin-right: 20px;
`

const Title = ({ title, className, showQry, click }) => (
  <TitleCss className={className}>
    {title}

    <TitleQryBtnCss>
      {showQry ? (
        <CaretUpOutlined style={{ color: "#3c81ce" }} onClick={click} />
      ) : (
        <CaretDownOutlined style={{ color: "#3c81ce" }} onClick={click} />
      )}
    </TitleQryBtnCss>
  </TitleCss>
)

class Report extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 1,
      total: 0,
      showQry: true,
      showDetail: false,
      isSimQry: true,
      simpType: "",
      dateS_sim: "",
      dateE_sim: "",
      currentMenuId: "0",
      tabValue: 1,
      menuIndex: 0,
      loading: true,
      reportList: [],
      industryList: [],
      reqValues: {},
      detailId: [],
      allmenuList:[],
      wxQryType:'1',
      wxQryTypeList:[{
        label: "标题",
        value: "1",
      },{
        label: "公众号",
        value: "2",
      },{
        label: "作者",
        value: "3",
      }],
      menuList: [
        {
          label: "全部报告",
          value: "0",
          list: [],
          pageIndex: 1,
          total: 0,
          isWeixin:0
        },
      ],
      tabList: [
        {
          label: "机构研报",
          value: 1,
          total:0,
          reportList:[],
          menuList:[]
        },
        {
          label: "微信研报",
          value: 2,
          total:0,
          reportList:[],
          menuList:[]
        },
        // {
        //   label: "首次评级研报",
        //   value: 3,
        // },
      ],
      qryParam:{}
    }
  }
  componentDidMount() {
    let menuId = this.props.location.state
      ? api.convertNull(this.props.location.state.clickMenuId)
      : ""
    
    if (menuId != "") {
      this.setState({
        currentMenuId: menuId,
      })
    } else {
      menuId = this.state.currentMenuId
    }

    if (api.checkLogin()) {
      this.getReportByMenuId(menuId)
      this.getIndustry()
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getIndustry() {
    let industry = api.localGet(config.industry_key)
    if (industry && industry.length > 0) {
      this.setState({
        industryList: JSON.parse(industry),
      })
    } else {
      api.getIndustry().then((res) => {
        if (res && res.Table && res.Table.length > 0) {
          let industryList = []
          industryList.push({ label: "全部行业", value: "" })
          res.Table.map((t) => {
            industryList.push({
              label: t.NAME,
              value: t.CODE,
            })
          })
          api.localSave(config.industry_key, JSON.stringify(industryList))
          this.setState({
            industryList: industryList,
          })
        }
      })
    }
  }

  getMenu(res) {
    if (res.Table1) {
      let menuList = this.state.menuList
      if (menuList.length == 1) {
        res.Table1.map((item) => {
          if (item.FTYPE == "2") {
            menuList.push({
              label: item.FNAME,
              value: item.GUID,
              list: [],
              pageIndex: 1,
              total: 0,
              isWeixin:'wx_report' == item.WEIXIN ? 1 :0
            })
          }
        })
        api.localSave(config.menu_key, JSON.stringify(menuList))
      }
      this.setState({
        menuList: menuList,
        allmenuList:menuList
      })
    }
  }
  setMenuList(reportList, total) {
    let menuList = this.state.menuList
    let menuIndex = this.state.menuIndex
    menuList[menuIndex].list = reportList
    menuList[menuIndex].total = total
    menuList[menuIndex].pageIndex = this.state.pageIndex
    this.setState({
      menuList: menuList,
    })
  }
  doQueryReport(opType, param) {
    this.setState({
      loading: true,
        qryParam:param,
    })
    api
      .queryReport(opType, param)
      .then((res) => {
        let reportList = []
        let total = res.total
        if (total>0) {
          res.data.map((item) => {
            reportList.push({
              title: item.FTITLE,
              guid: item.GUID,
              source: item.FSOURCE,
              author: item.AUTHOR,
              time: item.CREATETIME1,
              link:item.LINK
            })
          })
        }
        this.setState({
          reportList: reportList,
          total: total,
          loading: false,
        })
        this.setMenuList(reportList, total)
        console.log("total===", total)
      })
      .catch((e) => {
        this.setState({
          loading: false,
        })
      })
  }

  onKeyupRes(e){
    if(e.keyCode === 13) {
       this.simQuery(1)
     }
  }
  simQuery(pageIndex) {
    let param = {
      pageIndex: pageIndex, //this.state.pageIndex,
      qryType: this.state.simpType,
      qryText: this.refs.txtQryReport ? this.refs.txtQryReport.value:'',
      qryDateS: this.state.dateS_sim,
      qryDateE: this.state.dateE_sim,
      qryMenuId: this.state.currentMenuId,
      qryIsFirst: this.state.tabValue == 3 ? 1 : 0,
      qryIsWxReport:this.state.tabValue == 2 ? 1 : 0,
      qryHis: "",
    }

    this.setState({
      pageIndex: pageIndex,
    })
    
    this.doQueryReport("getWebInfoReport1", param)
  }
  seqQuery(values, pageIndex) {
    //console.log("seqQuery values==", values)
    const rangeValue = values["txtReqDate"]
    let param = {
      pageIndex: pageIndex, //this.state.pageIndex,
      qryAuthor: api.convertNull(values.txtReqAuthor),
      qrySource: api.convertNull(values.txtReqSource),
      qryStockCode: api.convertNull(values.txtReqCode),
      qryTitle: api.convertNull(values.txtReqTitle),
      qryDateS: rangeValue ? rangeValue[0].format("YYYY-MM-DD") : "",
      qryDateE: rangeValue ? rangeValue[1].format("YYYY-MM-DD") : "",
      qryIndustryCode: api.convertNull(values.txtReqIndustry),
      qryMenuId: values.txtReqMenu
        ? values.txtReqMenu
        : this.state.currentMenuId,
      qryHis: values.txtReqIsHis ? "1" : "0",
      qryIsDeep: "0",//values.txtReqIsDeep ? "1" : "0",
      qryIsFirst: this.state.tabValue == 3 ? 1 : 0,
      qryTab:this.state.tabValue == 2 ? 1 : 0,
      qryIsWxReport:this.state.tabValue == 2 ? 1 : 0,
    }
    this.doQueryReport("getWebInfoReportReq1", param)
  }
  getReportByMenuId(menuId) {
    //console.log("menuId==", menuId)
    let pageIndex = this.state.pageIndex
    api
      .getReportByMenuId(menuId, pageIndex)
      .then((res) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
        if (!api.checkInfoLogin(res)) {
          message.error("对不起，请先登录")
          this.props.history.push("/")
        }
        let reportList = []
        let total = 0
        if (res.Table) {
          res.Table.map((item) => {
            reportList.push({
              title: item.FTITLE,
              guid: item.GUID,
              source: item.FSOURCE,
              author: item.AUTHOR,
              time: item.CREATETIME1,
            })
            total = item.ALLCOUNT
          })
        }
        this.getMenu(res)

        this.setState({
          reportList: reportList,
          total: total,
          loading: false,
        })
        this.setMenuList(reportList, total)
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }
  changeTab(v) {
    console.log('v==',v)
    let mList = this.state.allmenuList
    console.log('mList=',mList)
    if (v == 2){
      mList=mList.filter(item=>{
         return item.isWeixin==1 || item.value=='0'
      })//.sort(function(a,b){ return a.label.localeCompare(b.label)}) 

      // mList.map((item,index) => {
      //   if(item.value == '0'){
      //     mList.unshift(mList.splice(index , 1)[0]);
      //   }
      // })
      
    }
    console.log('mList=',mList)
    this.setState(
      {
        currentMenuId:'0',
        isSimQry: true,
        tabValue: v,
        menuList:mList
      },
      () => {
        this.simQuery(1)
      }
    )
    // if (v == 2) {
    //   this.setState(
    //     {
    //       isSimQry: true,
    //       tabValue: v,
    //     },
    //     () => {
    //       this.simQuery(1)
    //     }
    //   )
    // } else {
    //   this.setState(
    //     {
    //       isSimQry: true,
    //       tabValue: v,
    //     },
    //     () => {
    //       this.simQuery(1)
    //     }
    //   )
    // }
    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // }, 1000)
  }
  changeMenu(v, index) {
    let selectMenu = this.state.menuList[index]
    this.setState({
      currentMenuId: v,
      menuIndex: index,
      showDetail: false,
      pageIndex: selectMenu.pageIndex,
      reportList: selectMenu.list,
    })
    if (this.state.currentMenuId == v) return
    if (this.state.tabValue==2){
      this.setState(
        {
          currentMenuId: v,
          menuIndex: index,
          showDetail: false,
          pageIndex: selectMenu.pageIndex,
          reportList: selectMenu.list,
        },
        () => {
          this.simQuery(1)
        }
      )
    }else{
        if (selectMenu.list.length == 0) {
          this.setState(
            {
              currentMenuId: v,
              menuIndex: index,
              showDetail: false,
              pageIndex: selectMenu.pageIndex,
              reportList: selectMenu.list,
            },
            () => {
              this.getReportByMenuId(v)
            }
          )
          
        }
      }
  }
  goDetail(guid, index,link) {
    //this.props.history.push("/report/detail/" + guid)
    //this.renderDetail(guid)
    // this.setState({
    //   showDetail: true,
    //   detailId: guid,
    // })
    let detailUrl = api.convertNull(link) == '' ? "/report/detail/" + guid :link
    window.open(detailUrl, "_blank")
    //this.context.router.history.push(`/report/detail/${guid}`)
  }
  detailMenuClick(clickMenuId, index) {
    this.setState({
      showDetail: false,
    })
  }
  onChange = (e) => {
    const value = e.target.value
    this.setState({
      simpType: value,
    })
  }
  onDateChange_sim(value, dateString) {
    this.setState({
      dateS_sim: dateString[0],
      dateE_sim: dateString[1],
    })
  }
  industryChange(selectIndustry) {
    //console.log("selectIndustry==", selectIndustry)
  }
  menuChange(selectedMenu) {
    //console.log("selectedMenu==", selectedMenu)
  }
  wxQryTypeChange(selectedWxQryType){
    this.setState({
       wxQryType:selectedWxQryType
    })
  }
  onFormFinishWx(values) {
    console.log('values==',values)
    let reqValues={
      txtReqDate:values.txtReqDate,
      txtReqMenu:values.txtReqMenu,
      txtReqSource:values.txtReqSource,//values.txtWxQry.type && values.txtWxQry.type=='2' ? values.txtWxQry.text:'',
      txtReqAuthor:values.txtWxQry.type && values.txtWxQry.type=='3' ? values.txtWxQry.text:'',
      txtReqTitle:!values.txtWxQry.type || values.txtWxQry.type=='1' ? values.txtWxQry.text:'',
    }
    this.setState({
      reqValues: reqValues,
      pageIndex: 1,
    })
    console.log('reqValues==',reqValues)
    this.seqQuery(reqValues, 1)
  }
  onFormFinish(values) {
    this.setState({
      reqValues: values,
      pageIndex: 1,
    })
    this.seqQuery(values, 1)
  }
  btnShowQry() {
    this.setState({
      showQry: !this.state.showQry,
    })
  }
  btnReqQry() {
    let _isSimQry = this.state.isSimQry
    this.setState({
      isSimQry: !_isSimQry,
    })
  }
  onSwitchChange(checked) {
    //console.log("isHis=", checked)
  }

  onDateOk_sim(value) {
   // console.log("onOk: ", value)
  }
  onPageChange(current) {
    this.setState({
      pageIndex: current,
    })
    if (this.state.tabValue == 2 || !this.state.isSimQry){
      this.seqQuery(this.state.reqValues, current)
    }else{
      this.simQuery(current)
    }
  }

  render() {
    const {
      tabList,
      tabValue,
      loading,
      menuIndex,
      currentMenuId,
      pageIndex,
      total,
      menuList,
      wxQryTypeList,
      reportList,
      simpType,
      isSimQry,
      industryList,
      showQry,
      showDetail,
      detailId,
      qryParam,

    } = this.state
    return (
      <div className="box">
         <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, i) => {
                return (
                  <li
                    key={"tab-" + i}
                    className={tabValue === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div> 
        <Spin spinning={loading}>
          {showDetail ? (
            <ReportDetail
              guid={detailId}
              menuList={menuList}
              currentMenuIndex={menuIndex}
              currentMenuId={currentMenuId}
              changeMenu={this.changeMenu.bind(this)}
            ></ReportDetail>
          ) : (
            <div className="list">
              <div
                style={{
                  background:
                    "linear-gradient(to bottom, #E5F1FF 0%, transparent 20%)",
                }}
              >
                <div className="list_content">
                  {showQry ? (
                    <div>
                      { tabValue == 1 ? (
                        <div>
                      {isSimQry ? (
                        <div className="search">
                          <div className="search_ipt">
                            <section
                              className={`${
                                simpType == "9" ? "_iptdate" : "_ipt"
                              }`}
                            >
                              <p>
                                {simpType == "9" ? (
                                  <RangePicker
                                    locale={locale}
                                    onChange={this.onDateChange_sim.bind(this)}
                                    onOk={this.onDateOk_sim}
                                  />
                                ) : (
                                  <input
                                    placeholder="输入搜索关键词"
                                    ref="txtQryReport" onKeyUp={this.onKeyupRes.bind(this)}
                                  />
                                )}
                              </p>
                              <p onClick={() => this.simQuery(1)}></p>
                            </section>

                            <div
                              className="_btn"
                              onClick={() => this.btnReqQry()}
                            >
                              高级搜索
                            </div>
                          </div>
                          <div className="search_list">
                            <Radio.Group
                              onChange={this.onChange}
                              selectedValue={simpType}
                            >
                              <Radio value={""}>智能搜索</Radio>
                              <Radio value={"1"}>报告标题</Radio>
                              <Radio value={"2"}>作者</Radio>
                              <Radio value={"3"}>出处</Radio>
                              <Radio value={"4"}>股票名称</Radio>
                              <Radio value={"5"}>股票代码</Radio>
                              <Radio value={"6"}>评级</Radio>
                              <Radio value={"7"}>行业</Radio>
                              {/* <Radio value={"8"}>香港海外</Radio> */}
                              <Radio value={"9"}>日期</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      ) : (
                        <div className="search">
                          <Form
                            name="basic"
                            onFinish={this.onFormFinish.bind(this)}
                            autoComplete="off"
                          >
                            <Row gutter={24}>
                              <Col span={8} key={1}>
                                <Form.Item label="报告标题" name="txtReqTitle">
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={8} key={2}>
                                <Form.Item label="代码/简称" name="txtReqCode">
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={8} key={3}>
                                <Form.Item label="机构出处" name="txtReqSource">
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={8} key={4}>
                                <Form.Item label="报告作者" name="txtReqAuthor">
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={8} key={7}>
                                <Form.Item
                                  label="所属行业"
                                  name="txtReqIndustry"
                                >
                                  <Select
                                    options={industryList}
                                    onChange={this.industryChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} key={71}>
                                <Form.Item label="所属栏目" name="txtReqMenu">
                                  <Select
                                    options={menuList}
                                    onChange={this.menuChange}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={11} key={5}>
                                <Form.Item label="起止时间" name="txtReqDate">
                                  <RangePicker
                                    format={config.dateFormat}
                                    locale={locale}
                                  />
                                </Form.Item>
                                {/* <Form.Item label="" name="txtReqEDate">
                                  <DatePicker
                                    format={config.dateFormat}
                                    locale={locale}
                                  />
                                </Form.Item> */}
                              </Col>

                              {/* <Col span={4} key={8}>
                                <Form.Item label="历史研报" name="txtReqIsHis">
                                  <Switch onChange={this.onSwitchChange} />
                                </Form.Item>
                              </Col> */}
                              {/* <Col span={4} key={9}>
                                <Form.Item label="深度报告" name="txtReqIsDeep">
                                  <Switch onChange={this.onDeepChange} />
                                </Form.Item>
                              </Col> */}
                              <Col span={5} key={10}>
                                <Button type="primary" htmlType="submit">
                                  搜索
                                </Button>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                  type="primary"
                                  onClick={() => this.btnReqQry()}
                                >
                                  简单搜索
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}
                      </div>
                      ) : (
                        // 微信搜索
                        <div className="search">
                          <Form
                            name="basicWx"
                            onFinish={this.onFormFinishWx.bind(this)}
                            autoComplete="off"
                          >
                            <Row gutter={24}>
                              <Col span={4} key={71}>
                                <Form.Item label="栏目" name="txtReqMenu">
                                  <Select defaultValue="0"
                                    options={menuList}
                                    onChange={this.menuChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4} key={711}>
                                <Form.Item label="出处" name="txtReqSource">
                                   <Input />
                                </Form.Item>
                              </Col>
                              <Col span={7} key={5}>
                                <Form.Item label="起止时间" name="txtReqDate">
                                  <RangePicker
                                    format={config.dateFormat}
                                    locale={locale}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={7} key={4}>
                              <Form.Item label="">
                                  <Form.Item
                                    name={['txtWxQry', 'type']}
                                    noStyle
                                  >
                                    <Select style={{width:'90px'}} defaultValue="1"
                                        options={wxQryTypeList}
                                        onChange={this.wxQryTypeChange.bind(this)}
                                      />
                                  </Form.Item>
                                  <Form.Item
                                    name={['txtWxQry', 'text']}
                                    noStyle
                                  >
                                    <Input style={{width:'210px'}}/>
                                  </Form.Item>
                              </Form.Item>
                              
                              </Col>
                              <Col span={1} key={10}>
                                <Button type="primary" htmlType="submit">
                                  搜索
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        )}
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <ul className="table">
                    {
                      //new Array(10).fill(1).map((t, i) => {
                      reportList.map((t, i) => {
                        return (
                          // <li
                          //   onClick={() => this.goDetail(t.guid, i,t.link)}
                          //   key={t.guid}
                          // >
                          //   <p>{t.title}</p>
                          
                           tabValue == "1" ? (
                        <Link className="link" key={t.guid} to={{pathname:"/report/detail/" + t.guid,search:JSON.stringify({ qryText:qryParam.qryText})}} target="_blank">
                        <li
                          key={t.guid}
                        >
                          <div
                              className="innerhtml"
                              dangerouslySetInnerHTML={{
                                __html: api.qryhightlight(t.title,qryParam.qryText),
                              }}
                            ></div>
                            <p>
                              <span>来自 {t.source + "  " + t.author}</span>
                              <span>{t.time}</span>
                            </p>
                          </li>
                          </Link>
                          ) : (
                            <li
                            onClick={() => this.goDetail(t.guid, i,t.link)}
                          key={t.guid}
                        >
                          <div
                              className="innerhtml"
                              dangerouslySetInnerHTML={{
                                __html: api.qryhightlight(t.title,qryParam.qryText),
                              }}
                            ></div>
                            <p>
                              <span>来自 {t.source + "  " + t.author}</span>
                              <span>{t.time}</span>
                            </p>
                          </li>

                          )

                        )
                      })
                    }
                  </ul>
                  <div style={{ paddingBottom: "85px" }}>
                    <Pagination
                      className="ant-table-pagination ant-table-pagination-center"
                      current={pageIndex}
                      pageSize={config.pageSize}
                      total={total}
                      showSizeChanger={false}
                      onChange={this.onPageChange.bind(this)}
                    ></Pagination>
                  </div>
                </div>
              </div>

              <div className="recommand">
                <div className="item">
                  <p className="item_label">研究报告</p>
                  <div className="item_list">
                    {
                      /* {reportList.map((t, i) => {
                    return <p key={t.value}>{t.label}</p>
                  })} */
                      menuList.map((t, i) => {
                        return (
                          <p
                            key={t.value}
                            className={
                              currentMenuId === t.value ? "active" : ""
                            }
                            onClick={() => this.changeMenu(t.value, i)}
                          >
                            {t.label}
                          </p>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </Spin>
      </div>
    )
  }
}
export default Report
